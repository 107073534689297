/**
 * This is an example Listing page that lives on the route '/list-example'
 */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KaizenTable from "@kaizen-ui/table";
import { fetchApplicationList } from "../../api";
import { Layout } from "../../layout";
import Button, { ButtonGroup } from "@kaizen-ui/button";
import "./styles.css";
import AccessibilityContext from "../../components/auth/UserPermissionContext";
import WithAuth from "../../components/auth/WithAuth";
import WithAdminUIAuth from "../../components/auth/WithAdminUIAuth";

// Kaizen table has small typing bug at the moment. This ensures columns and
// data props are registered correctly
const Table = KaizenTable;

const ApplicationList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageIndex, setPage] = useState(0);
  const [pageSize, setSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sorting, setSorting] = useState([{ id: "modified_date", desc: true }]);
  const [filtering, setFiltering] = useState([{ id: "test", value: "test" }]);
  const [totalResults, setTotalResults] = useState(0);
  const [showmine, setShowMine] = useState(false);
  const [fetching, setFetching] = useState(true);

  //permissions

  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "application"
  )[0];

  const hiddenColumns = ["applicationid"];
  const columns = [
    { accessor: "applicationid" },
    { accessor: "applicationname", Header: "Application Name" },
    { accessor: "applicationurl", Header: "Application URL" },
    { accessor: "applicationdescription", Header: "Application Description" },
  ];
  const IsArrayEqual = (first, second) => {
    return JSON.stringify(second) === JSON.stringify(first);
  };

  const rowColorChange = (row) => {
    const applicationid = row.applicationid;
    if (applicationid % 2 === 0) {
      return false;
    } else return true;
  };

  const handleFetchData = useCallback(
    (params) => {
      const insorting = JSON.stringify(params.sortBy);
      const inSortby = JSON.parse(insorting);
      const infiltering = JSON.stringify(params.filters);
      const inFilterBy = JSON.parse(infiltering);
      //console.log('currentPage : '+currentPage)
      if (IsArrayEqual(inSortby, sorting) === false) {
        //console.log('SortBY is not equal')
        setSorting(inSortby);
      }
      if (IsArrayEqual(inFilterBy, filtering) === false) {
        //console.log('Filter is not equal')
        setFiltering(inFilterBy);
      }
      if (
        pageIndex !== params.pageIndex + 1 &&
        currentPage !== params.pageIndex + 1
      ) {
        //console.log('before pageIndex :' + pageIndex)
        setPage(params.pageIndex + 1);
        //console.log('after pageIndex :' + pageIndex)
      }
      if (pageSize !== params.pageSize) {
        //console.log('before pageSize :' + pageIndex)
        setSize(params.pageSize);
      }
    },
    [currentPage, pageIndex, pageSize, sorting, filtering]
  );
  useEffect(() => {
    if (permissions?.read === false) {
      navigate("/unauthorized");
    }
    if (pageIndex > 0) {
      setFetching(true);

      fetchApplicationList(pageIndex, pageSize, sorting, filtering).then(
        (data) => {
          if (!data) navigate("/serverError");
          if (data.statusCode === 401) {
            navigate("/unauthorized");
          } else {
            setData(data.contents);
            setCurrentPage(pageIndex);
            setTotalPages(Math.ceil(data.resultCount / pageSize));
            setTotalResults(data.resultCount);
            setShowMine(showmine);
            setFetching(false);
          }
        }
      );
    }
  }, [filtering, navigate, pageIndex, pageSize, showmine, sorting]);
  console.log(permissions?.read);

  if (permissions?.read) {
    return (
      <Layout
        key="laycustomresponse"
        title="Application List"
        headerChildren={
          <>
            <ButtonGroup key="btngrpcreateresponse">
              {permissions?.create ? (
                <Button
                  key="btncreateresponse"
                  icon={{ name: "ActionsCircleAdd", variant: "solid" }}
                  onClick={() => {
                    navigate("/application/0");
                  }}
                >
                  Create Application
                </Button>
              ) : (
                <div></div>
              )}
            </ButtonGroup>
          </>
        }
      >
        <Table
          key="tblapplication"
          initialState={{
            pageSize: pageSize,
            pageIndex: pageIndex,
            hiddenColumns: hiddenColumns,
          }}
          paginationPageSizeOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
          ]}
          rowOnClick={({ values }) =>
            navigate(`/application/${values.applicationid}`)
          }
          //rowActions={[{ "icon": {"name": "ActionsCircleDelete","variant": "solid"},"itemStyle": "critical","label": "Delete Custom Response", onClick:(values)=>{deleteCustomResponse(values)}}]}
          // @ts-ignore
          columns={columns}
          data={data}
          pageCount={totalPages}
          totalResults={totalResults}
          fetchData={handleFetchData}
          fetching={fetching}
          autoResetPage={false}
          autoResetSortBy={false}
          disableColumnHiding={true}
          disableExport={false}
          disableGlobalFilter={true}
          //rowActions={getRowActionDetails}
          getRowProps={(row) => ({
            style: {
              background: rowColorChange(row) ? "" : "grey",
            },
          })}
        />
      </Layout>
    );
  }
};

export default WithAuth(WithAdminUIAuth(ApplicationList));
