import React, { useState, useEffect } from "react";
import "core-js/features/url-search-params";
import jwt_decode from "jwt-decode";
import "./SignInD.css";
import GlobalFooter from "../../components/globalfooter";
import Header from "../../components/header";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { writeUser } from "../../common/loginutils";
import {
  UpdateUserLoggedIn,
  fetchApplicationsByUser,
  fetchUserDetailsByEmail,
  postAdderrorlog,
  postAddUserEmailLog,
  postAutenticationsuccessemailllog,
} from "../../api";
import { Link } from "react-router-dom";
import "../../styles/Loader.css";
import { InlineBanner } from "@kui-react/banner";

function Login() {
  const urlParams = new URLSearchParams(
    window.location.href.split(/(\?|#)/)[2]
  );

  const initialValues = {
    email: "",
  };
  const [aaemail, setEmail] = useState();

  const [userDependenciesLoaded, setUserDependenciesLoaded] = useState(false);
  const [maintenanceNotice, setmaintenanceNotice] = useState(false);
  const [maintenanceAnnouncement, setmaintenanceAnnouncement] = useState(false);

  const [maintenanceData, setMaintenanceData] = useState();
  const [state, setState] = useState({
    idToken: "",
    accessToken: "",
    expiresIn: 0,
    tokenType: "",
    email: "",
    returnUrl: "/dashboard",
  });
  const [errorState, setError] = useState({
    error: "",
  });

  const cookieObj = new URLSearchParams(
    document.cookie.replaceAll("&", "%26").replaceAll("; ", "&")
  );

  let cemail = localStorage.getItem("email");
  let text = "";
  let btn_text = "";
  let placeholder_text = "please enter email";
  // let cancel_btn_style = "";
  let disable = "";

  const UpdateLastLoginDate = async (email) => {
    const token = localStorage.getItem("ROCP_idToken");
    if (token !== "" && token !== null && token !== undefined) {
      await UpdateUserLoggedIn();

      // .then(async (data) => {
      //   if (data.status === 200 || data.statusCode === 200) {
      //     await data.json();
      //   }
      // })
      // .catch((error) => {
      //   console.log("error in update user");
      // })
      // .finally(() => {});
    }
  };

  // const redirectApplciation = async () => {
  //   const token = localStorage.getItem("ROCP_idToken");
  //   let email = localStorage.getItem("email");

  //   if (
  //     email !== "" &&
  //     email !== null &&
  //     email !== undefined &&
  //     token !== "" &&
  //     token !== null &&
  //     token !== undefined
  //   ) {
  //     var resdata = await fetchApplicationsByUser();
  //     if (resdata.statusCode === 401) {
  //       localStorage.clear();
  //       return "/login";
  //     }
  //     if (resdata.statusCode === 200) {
  //       var apps = resdata.data;
  //       if (apps !== null && apps.length && apps.length === 1) {
  //         let application = apps[0];
  //         // window.location.href = application.applicationurl;
  //         return application.applicationurl;
  //       }
  //     }

  //     return "";
  //   } else {
  //     localStorage.clear();
  //   }
  // };

  const CheckUserIsValid = async () => {
    const token = localStorage.getItem("ROCP_idToken");
    let email = localStorage.getItem("email");

    if (
      email !== "" &&
      email !== null &&
      email !== undefined &&
      token !== "" &&
      token !== null &&
      token !== undefined
    ) {
      var response = await fetchUserDetailsByEmail();
      localStorage.setItem("UserDetails", JSON.stringify(response.data));

      if (response) {
        if (response.statusCode === 404) {
          return "/usererror";
          // window.location.href = "/usererror";
        } else if (response.statusCode === 401) {
          localStorage.clear();
          localStorage.clear();
          //window.location.href = "/login";
          return "/login";
        }
        if (response.statusCode === 200) {
          var res = response.data;
          if (res.isactive === false) {
            //window.location.href = "/usererror";
            return "/usererror";
          } else if (res.earrestricted === true) {
            // window.location.href = "/usererror";
            return "/usererror";
          }
        }
      }

      return "";
    } else {
      localStorage.clear();
      localStorage.clear();
      return "/login";
    }
  };
  const handleLogin = async (email) => {
    if (email !== "" && email !== null && email !== undefined) {
      if (localStorage.getItem("renewal") === "inprogress") {
        return;
      }
      localStorage.removeItem("userinfo");
      localStorage.removeItem("token");
      localStorage.setItem(
        "returnUrl",
        window.location.pathname === "/"
          ? window.location.href + state.returnUrl
          : window.location.href
      );

      generateCodeChallenge().then((code_challenge) => {
        const qParams = new URLSearchParams({
          redirect_uri: process.env.REACT_APP_redirectUri,
          scope: "openid profile email",
          client_id: process.env.REACT_APP_clientId,
          response_type: "code",
          nonce: window.crypto.getRandomValues(new Uint8Array(32)),
          state: "returnUrl",
          code_challenge: code_challenge,
          code_challenge_method: "S256",
          login_hint: email,
        });
        try {
          const authurl = process.env.REACT_APP_authorizationEndpoint;
          const url = `${authurl}?${qParams}`;
          window.location.assign(url);
        } catch (e) {
          localStorage.clear();
          console.error(e);
          localStorage.setItem("IsUserLoggedIn", "false");
          // window.location.assign("/login");
        }
      });
    }
  };

  const checkLoggedinUser = async (skip) => {
    const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
    if (IsUserLoggedIn === "inprogress") {
      return true;
    }
    var retvalue = false;
    const loggedInUser = localStorage.getItem("userinfo");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser) {
        if (validateToken(foundUser)) {
          if (skip === undefined) {
            await setUserLoggedIn();
          }
          return true;
        }
      }
    } else {
      if (
        window.location.search === "" ||
        !window.location.search.includes("code")
      ) {
        let email = localStorage.getItem("email");
        if (email !== "" && email !== null && email !== undefined) {
          handleLogin(email);
        } else {
          localStorage.clear();
        }
      }
    }
    return retvalue;
  };

  const validateToken = (foundUser) => {
    const storedToken = JSON.parse(localStorage.getItem("token"));
    if (storedToken) {
      if (storedToken.error === undefined) {
        var currentDate = new Date().getTime();
        var timediff = currentDate / 1000 - parseInt(foundUser.iat);
        var token_timeout =
          parseInt(foundUser.exp) - parseInt(foundUser.iat) - 300;

        if (timediff <= token_timeout) {
          localStorage.setItem("ROCP_idToken", storedToken.id_token);
          getUserInfo(storedToken, false);
          return true;
        } else if (timediff > token_timeout + 1) {
          renewAccessToken(storedToken, foundUser);
          return true;
        }
      }
    }
    handleLogin();
    return true;
  };

  const getAccessToken = async (code, reload = true) => {
    var gettingAccessToken = localStorage.getItem("IsUserLoggedIn");
    if (gettingAccessToken !== "inprogress") {
      clearStorage();
      localStorage.removeItem("token");
      var redirectUri = process.env.REACT_APP_redirectUri;
      if (code !== undefined) {
        localStorage.setItem("IsUserLoggedIn", "inprogress");

        var code_verifier = localStorage.getItem("CV");
        if (code_verifier === null) {
          window.location.assign(redirectUri);
        }
        const data = [
          `code=${code}`,
          `grant_type=authorization_code`,
          `code_verifier=${code_verifier}`,
          `redirect_uri=${redirectUri}`,
        ].join("&");

        var headerstr = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        fetch(process.env.REACT_APP_tokenEndpoint, {
          headers: headerstr,
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then(
            (result) => {
              if (result !== null) {
                if (result["error"] === undefined) {
                  if (result["id_token"]) {
                    setSessionToken(JSON.stringify(result));
                    getUserInfo(result, reload);

                    (async () => {
                      let Log = {
                        error: "Success",
                        emailid: localStorage.getItem("email"),
                        error_description: JSON.stringify(result),
                      };

                      await postAutenticationsuccessemailllog(Log)
                        .then((data) => {})
                        .catch((error) => {})
                        .finally(() => {});
                    })();

                    localStorage.setItem("gettingAccessToken", false);
                  }
                } else {
                  (async () => {
                    let errorLog = {
                      error: result["error"],
                      emailid: localStorage.getItem("email"),
                      error_description: result["error_description"],
                    };

                    await postAdderrorlog(errorLog)
                      .then((data) => {})
                      .catch((error) => {})
                      .finally(() => {});
                  })();
                  localStorage.removeItem("token");
                  window.location.assign("/autherror");
                }
              }
            },
            (error) => {
              setError(error);
              localStorage.setItem("IsUserLoggedIn", "false");
            }
          );
      }
    }
  };

  const setSessionToken = (token_str) => {
    if (token_str !== "") {
      localStorage.setItem("token", token_str);
      let token_obj = JSON.parse(token_str !== null ? token_str : "");
      localStorage.setItem("ROCP_idToken", token_obj.id_token);
    }
  };

  const handleRenewal = async () => {
    generateCodeChallenge().then((code_challenge) => {
      const iframe = document.createElement("iframe");
      iframe.id = "nviframe";
      const searchParams = new URLSearchParams({
        redirect_uri: process.env.REACT_APP_redirectUri,
        client_id: process.env.REACT_APP_clientId,
        prompt: "none",
        login_hint: localStorage.getItem("email"),
        response_type: "code",
        scope: "openid profile email",
        state: "renewal",
        code_challenge_method: "S256",
        code_challenge: code_challenge,
      });

      iframe.src = `${
        process.env.REACT_APP_authorizationEndpoint
      }?${searchParams.toString()}`;

      iframe.hidden = true;
      const timeoutID = setTimeout(() => {
        localStorage.setItem("IsUserLoggedIn", "false");
        removeIFrame();
        Promise.resolve(false);
      }, 60000);
      window.addEventListener("message", (event) => {
        if (event?.data === "success") {
          clearTimeout(timeoutID);
          removeIFrame();

          Promise.resolve(JSON.parse(localStorage.getItem("token")));
        } else if (event?.data === "failure") {
          clearTimeout(timeoutID);
          removeIFrame();
          localStorage.setItem("IsUserLoggedIn", "false");

          localStorage.clear();

          Promise.resolve(false);
        }
      });
      document.body.appendChild(iframe);
    });
  };

  const renewAccessToken = async (token, user) => {
    if (token !== undefined) {
      localStorage.setItem("IsUserLoggedIn", "false");

      handleRenewal().then((value) => {
        if (value !== "false" && value !== undefined) {
          getUserInfo(value, false);
        }
      });
    }
  };

  const removeIFrame = () => {
    const nviframe = document.getElementById("nviframe");
    if (nviframe) {
      document.body.removeChild(nviframe);
    }
  };

  const b64url = (array) => {
    const b64 = btoa(String.fromCharCode.apply(null, array));
    return b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  };

  const generateCodeChallenge = async () => {
    // Generate a 32-octet random sequence
    const rand = window.crypto.getRandomValues(new Uint8Array(32));

    // The PKCE code verifier is the base64url encoding of the random octets
    const codeVerifier = b64url(rand);
    localStorage.setItem("CV", codeVerifier);

    // Calculate the SHA-256 hash of the code verifier
    const data = new TextEncoder().encode(codeVerifier);
    var sha256 = await window.crypto.subtle.digest("SHA-256", data);

    // The PKCE code challenge is the base64url encoding of the hash
    return b64url(new Uint8Array(sha256));
  };

  const clearStorage = (removeAll = false) => {
    localStorage.removeItem("userinfo");
    localStorage.removeItem("user");
    localStorage.removeItem("name");
    localStorage.removeItem("sub");
    localStorage.removeItem("idp_name");
    localStorage.removeItem("email_verified");
    localStorage.removeItem("idp_id");
    localStorage.removeItem("issuer");
    localStorage.removeItem("external_id");
    localStorage.removeItem("preferred_username");
    localStorage.removeItem("updated_at");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("issued_at");

    if (removeAll) {
      localStorage.removeItem("token");
      localStorage.removeItem("ROCP_idToken");
      localStorage.removeItem("gettingAccessToken");
      localStorage.removeItem("CV");
      localStorage.removeItem("IsUserLoggedIn");
    }
  };
  const setUserLoggedIn = async () => {
    localStorage.setItem("IsUserLoggedIn", "true");
    const token = localStorage.getItem("ROCP_idToken");
    if (token !== "" && token !== null && token !== undefined) {
      await UpdateLastLoginDate(localStorage.getItem("email"));
    }
  };

  const getUserInfo = async (token, reload = true) => {
    if (token) {
      var idToken = token.id_token;
      if (idToken !== null && idToken !== undefined) {
        var tokenobj = jwt_decode(idToken);
        //  console.log(tokenobj);

        if (tokenobj?.email === undefined || tokenobj.email === null) {
          localStorage.setItem(
            "user",
            tokenobj.preferred_username.replace("@nvidia.com", "")
          );
          localStorage.setItem("email", tokenobj.preferred_username);
        } else {
          localStorage.setItem(
            "user",
            tokenobj.email.replace("@nvidia.com", "")
          );
          localStorage.setItem("email", tokenobj.email);
        }

        localStorage.setItem("userinfo", JSON.stringify(tokenobj));

        localStorage.setItem("name", tokenobj.name);
        localStorage.setItem("sub", tokenobj.sub);
        localStorage.setItem("idp_name", tokenobj.idp_name);
        localStorage.setItem("email_verified", tokenobj.email_verified);
        localStorage.setItem("idp_id", tokenobj.idp_id);
        localStorage.setItem("issuer", tokenobj.iss);
        localStorage.setItem("external_id", tokenobj.external_id);
        localStorage.setItem("preferred_username", tokenobj.preferred_username);
        localStorage.setItem("updated_at", tokenobj.updated_at);
        localStorage.setItem("expire_at", tokenobj.exp);
        localStorage.setItem("issued_at", tokenobj.iat);
        localStorage.setItem("name", tokenobj.name);
        //localStorage.removeItem("renewal");

        if (reload) {
          await setUserLoggedIn();
          if (state.returnUrl !== undefined) {
            let originredirecturi = localStorage.getItem("originredirecturi");
            let deeplinkurl = localStorage.getItem("deeplink");
            if (
              originredirecturi !== "" &&
              originredirecturi !== undefined &&
              originredirecturi !== null
            ) {
              window.location.assign(originredirecturi);
            } else if (
              deeplinkurl !== "" &&
              deeplinkurl !== undefined &&
              deeplinkurl !== null
            ) {
              window.location.assign(deeplinkurl);
              localStorage.removeItem("deeplink");
            } else if (
              localStorage.getItem("TokenExpiredPage") !== null &&
              localStorage.getItem("TokenExpiredPage") !== undefined
            ) {
              //update last login date
              const token = localStorage.getItem("ROCP_idToken");
              if (token !== "" && token !== null && token !== undefined) {
                await UpdateLastLoginDate(localStorage.getItem("email"));
              }
              window.location.assign(
                window.location.origin +
                  localStorage.getItem("TokenExpiredPage")
              );
              localStorage.removeItem("TokenExpiredPage");
            } else {
              let userValidurl = await CheckUserIsValid();
              if (
                userValidurl !== "" &&
                userValidurl !== undefined &&
                userValidurl !== null
              ) {
                window.location.assign(window.location.origin + userValidurl);
              } else {
                let apps = await fetchApplicationsByUser();
                localStorage.setItem(
                  "ApplicationDetails",
                  JSON.stringify(apps.data)
                );
                if (
                  apps !== "" &&
                  apps !== undefined &&
                  apps !== null &&
                  apps.length &&
                  apps.length === 1
                ) {
                  window.location.href = apps[0].applicationurl;
                } else {
                  window.location.assign(
                    window.location.origin + state.returnUrl
                  );
                }
              }
            }
          }
        } else {
          window.parent.postMessage("success");
        }
      }
    }
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (event) => {
    let userEmail = document.getElementById("email").value;

    if (userEmail !== "" && userEmail !== null && userEmail !== undefined) {
      if (validateEmail(userEmail)) {
        let errorLog = {
          error: "",
          emailid: userEmail,
          error_description: "User is trying to login",
        };

        postAddUserEmailLog(errorLog)
          .then((data) => {})
          .catch((error) => {})
          .finally(() => {});

        event.preventDefault();

        setEmail(document.getElementById("email").value);
        if (cemail !== "" && cemail !== null && cemail !== undefined) {
          localStorage.setItem("email", cemail);
          handleLogin(cemail);
        } else {
          localStorage.setItem("email", document.getElementById("email").value);
          handleLogin(document.getElementById("email").value);
        }
      } else {
        localStorage.clear();

        // document.getElementById("email").value = "";
      }
    }
  };

  useEffect(() => {
    (async () => {
      //  var response = await GetMaintenanceNotice();
      // if (response) {
      //   console.log(response);
      //   var res = response;

      //   if (
      //     res.notice !== undefined &&
      //     res.notice != null &&
      //     res.notice === "yes"
      //   ) {
      //     setmaintenanceNotice(true);
      //     setMaintenanceData(res);
      //   }
      //   if (
      //     res.announcement !== undefined &&
      //     res.announcement != null &&
      //     res.announcement === "yes"
      //   ) {
      //     setmaintenanceAnnouncement(true);
      //     setMaintenanceData(res);
      //   }
      // }

      let deeplinkurl = localStorage.getItem("deeplink");
      let token = localStorage.getItem("ROCP_idToken");

      if (
        deeplinkurl !== "" &&
        deeplinkurl !== undefined &&
        deeplinkurl !== null &&
        token !== "" &&
        token !== undefined &&
        token !== null
      ) {
        setUserDependenciesLoaded(false);
        localStorage.removeItem("deeplink");
      } else {
        if (!localStorage.getItem("email")) {
          setEmail(localStorage.getItem("email"));
        }

        let expDate = localStorage.getItem("expire_at");
        expDate = parseInt(expDate);
        var curDate = new Date();
        var seconds = Math.round(curDate.getTime() / 1000);
        if (isNaN(expDate) || expDate < seconds) {
          clearStorage();
        }

        if (cemail !== "" && cemail !== null && cemail !== undefined) {
          if (validateEmail(cemail)) {
            if (!checkLoggedinUser()) {
              if (
                window.location.pathname !== "/login" ||
                window.location.pathname !== "/"
              ) {
                if (window.location.search !== "") {
                  var searchParams = new URLSearchParams(
                    window.location.search
                  );

                  const token = localStorage.getItem("ROCP_idToken");
                  if (token !== "" && token !== null && token !== undefined) {
                    await UpdateLastLoginDate(localStorage.getItem("email"));
                  }
                  if (searchParams.get("code")) {
                    var code = searchParams.get("code");
                    getAccessToken(code);
                  } else if (
                    searchParams.get("error_description") ||
                    searchParams.get("error")
                  ) {
                    let Error_description = decodeURIComponent(
                      searchParams.get("error_description")
                    );
                    let server_error = decodeURIComponent(
                      searchParams.get("error")
                    );
                    if (
                      Error_description ===
                        "Fails+to+process+authorization+callback" ||
                      server_error === "server_error"
                    ) {
                      window.location.assign("/autherror");
                    }
                  }
                } else {
                  setUserDependenciesLoaded(true);
                  localStorage.removeItem("email");
                  localStorage.removeItem("CV");
                  localStorage.removeItem("returnUrl");
                }
              } else {
                let searchParamsurl = new URLSearchParams(
                  window.location.search
                );
                if (
                  searchParamsurl.get("error_description") ||
                  searchParamsurl.get("error")
                ) {
                  let Error_description = decodeURIComponent(
                    searchParams.get("error_description")
                  );
                  let server_error = decodeURIComponent(
                    searchParams.get("error")
                  );
                  if (
                    Error_description ===
                      "Fails+to+process+authorization+callback" ||
                    server_error === "server_error"
                  ) {
                    window.location.assign("/autherror");
                  }
                }
              }
            } else {
              if (localStorage.getItem("renewal") !== "inprogress") {
                if (
                  urlParams.has("state") &&
                  urlParams.get("state") === "renewal"
                ) {
                  localStorage.setItem("renewal", "inprogress");
                  getAccessToken(urlParams.get("code"), false);
                } else {
                  if (urlParams.has("code")) {
                    getAccessToken(urlParams.get("code"));
                  } else if (localStorage.getItem("token")) {
                    // const token = localStorage.getItem("ROCP_idToken");

                    // if (token !== "" && token !== null && token !== undefined) {
                    //   await UpdateLastLoginDate(localStorage.getItem("email"));
                    // }
                    let originredirecturi =
                      localStorage.getItem("originredirecturi");
                    let deeplinkurl = localStorage.getItem("deeplink");
                    await setUserLoggedIn();
                    if (
                      originredirecturi !== "" &&
                      originredirecturi !== undefined &&
                      originredirecturi !== null
                    ) {
                      window.location.assign(originredirecturi);
                    } else if (
                      deeplinkurl !== "" &&
                      deeplinkurl !== undefined &&
                      deeplinkurl !== null
                    ) {
                      window.location.assign(deeplinkurl);
                      localStorage.removeItem("deeplink");
                    } else if (
                      localStorage.getItem("TokenExpiredPage") !== null &&
                      localStorage.getItem("TokenExpiredPage") !== undefined
                    ) {
                      //update last login date
                      const token = localStorage.getItem("ROCP_idToken");
                      if (
                        token !== "" &&
                        token !== null &&
                        token !== undefined
                      ) {
                        await UpdateLastLoginDate(
                          localStorage.getItem("email")
                        );
                      }
                      window.location.assign(
                        window.location.origin +
                          localStorage.getItem("TokenExpiredPage")
                      );
                      localStorage.removeItem("TokenExpiredPage");
                    } else {
                      let userValidurl = await CheckUserIsValid();
                      if (
                        userValidurl !== "" &&
                        userValidurl !== undefined &&
                        userValidurl !== null
                      ) {
                        //window.location.assign(userValidurl);
                        window.location.assign(
                          window.location.origin + userValidurl
                        );
                      } else {
                        let apps = await fetchApplicationsByUser();
                        localStorage.setItem(
                          "ApplicationDetails",
                          JSON.stringify(apps.data)
                        );
                        if (
                          apps !== "" &&
                          apps !== undefined &&
                          apps !== null &&
                          apps.length &&
                          apps.length === 1
                        ) {
                          //If user has only NGC Portal Access Write details in iframe
                          if (
                            apps.applicationname.toUpperCase() === "NVIDIA NGC"
                          ) {
                            if (
                              localStorage.getItem("idp_id") !== "" &&
                              localStorage.getItem("idp_id") !== null &&
                              localStorage.getItem("idp_id") !== undefined &&
                              localStorage.getItem("sub") !== "" &&
                              localStorage.getItem("sub") !== null &&
                              localStorage.getItem("sub") !== undefined
                            ) {
                              writeUser(
                                localStorage.getItem("email"),
                                localStorage.getItem("idp_id"),
                                localStorage.getItem("expire_at"),
                                localStorage.getItem("sub")
                              );
                            }
                          }

                          window.location.href = apps[0].applicationurl;
                        } else {
                          window.location.assign(
                            window.location.origin + state.returnUrl
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            localStorage.clear();
            cemail = "";
          }
        } else {
          setUserDependenciesLoaded(true);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          cemail = "";
          localStorage.removeItem("email");
          localStorage.removeItem("CV");
          localStorage.removeItem("returnUrl");
        }
      }
    })();
    // const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
  }, []);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
  });

  return (
    <div>
      {!userDependenciesLoaded ? (
        <div className="loader-container">
          {" "}
          <div className="loader"></div>
        </div>
      ) : (
        <div className="sign-in">
          <Header
            headerHeading="NVIDIA Application Hub Login"
            loginHeader={true}
            dashboardHeader={false}
          ></Header>

          {maintenanceNotice ? (
            <div>
              <InlineBanner status="info">
                <div
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(30%, 1%)",
                  }}
                >
                  <h1
                    style={{
                      color: "var(--brand-nvidia-green, #76b900)",
                      font: "var('NVIDIA Sans', 'sans-serif')",
                    }}
                  >
                    System Maintenance Notice
                  </h1>
                  <br></br>
                  <p>Dear Valued Customer,</p> <br></br>
                  <p>
                    Our System will be undergoing maintenance to better serve
                    you.
                  </p>
                  <p>
                    <b> NVIDIA Application Hub</b> will be temporarily
                    unavailable during the following periods:
                  </p>
                  <br></br>
                  <p>
                    {maintenanceData.fromDate} through {maintenanceData.toDate}
                  </p>{" "}
                  <br></br>
                  <p>
                    We apologize for any incovenience and thank you for your
                    understading.
                  </p>
                </div>
              </InlineBanner>
            </div>
          ) : (
            <div>
              {maintenanceAnnouncement ? (
                <InlineBanner status="warning">
                  {" "}
                  {/*<p>*/}
                  {/*  <b>*/}
                  {/*    {" "}*/}
                  {/*    NVIDIA Application Hub maintenance scheduled on{" "}*/}
                  {/*    {maintenanceData.fromDate} through{" "}*/}
                  {/*    {maintenanceData.toDate}*/}
                  {/*  </b>*/}
                  {/*</p>*/}
                  <p>
                    {" "}
                    NVIDIA Application Hub undergoing maintenance that may
                    impact services. During this time, If you experience issues
                    please attempt your tasks at later time.
                  </p>
                </InlineBanner>
              ) : (
                <span></span>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid, touched, errors }) => (
                  <form id="login-form">
                    <div className="signbox">
                      <div className="login-container">
                        <div className="login-box">
                          <div className="sign-in-rectangle-15"></div>
                          <div className="sign-in-heading">
                            <div className="sign-in-heading-medium">
                              Let’s get started{" "}
                            </div>
                          </div>
                          <br></br>
                          <div className="sign-in-frame-41">
                            <div className="sign-in-text-input">
                              <div className="sign-in-text-input2">
                                <Field
                                  type="text"
                                  id="email"
                                  name="email"
                                  // onChange={handleChange}
                                  disable={disable}
                                  className="sign-in-text-input sign-in-text-input2 sign-in-input sign-in-input-contents sign-in-input-text sign-in-input-label sign-in-label"
                                  placeholder={placeholder_text}
                                  defaultValue={cemail}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="login-button-container">
                            <div class="box ">
                              <Link
                                className="sign-in-text sign-in-text-medium"
                                to="/needhelp"
                              >
                                Need help
                              </Link>
                            </div>
                            <div class="box">
                              <button
                                class="sign-in-cta-button sign-in-cta-button-primary2"
                                type="submit"
                                onClick={handleSubmit}
                                disabled={isSubmitting || !isValid}
                              >
                                {isSubmitting ? "Submitting..." : "Sign In"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}

          <GlobalFooter></GlobalFooter>
        </div>
      )}
    </div>
  );
}

export default Login;
