import jwt_decode from "jwt-decode";
// import config from "../process.env.REACT_APP_json";

export const renewAccessTokenCurrentUser = async () => {
  var retvalue = false;
  const loggedInUser = localStorage.getItem("userinfo");
  if (loggedInUser) {
    const foundUser = JSON.parse(loggedInUser);
    if (foundUser) {
      if (await validateToken(foundUser)) {
        return true;
      }
    }
  } else {
    return false;
  }
  return retvalue;
};
export const isTokenExpiredasync = async () => {
  let expDate = localStorage.getItem("expire_at");
  expDate = parseInt(expDate);
  var curDate = new Date();
  var seconds = Math.round(curDate.getTime() / 1000);
  if (isNaN(expDate) || expDate < seconds) {
    return true;
  }
  // Check if the token is expired by comparing with the current time
};
const renewAccessToken = async (token, user) => {
  if (token !== undefined) {
    localStorage.setItem("IsUserLoggedIn", "false");
    //update last login date
    // localStorage.removeItem("userinfo");
    // localStorage.removeItem("ROCP_idToken");

    handleRenewal().then(async (value) => {
      if (value !== "false" && value !== undefined) {
        await getUserInfo(value, false);
      }
    });
  }
};

const validateToken = async (foundUser) => {
  const storedToken = JSON.parse(localStorage.getItem("token"));
  if (storedToken) {
    await renewAccessToken(storedToken, foundUser);
    return true;
  }

  return true;
};

const handleRenewal = async () => {
  generateCodeChallenge().then((code_challenge) => {
    const iframe = document.createElement("iframe");
    iframe.id = "nviframe";
    const searchParams = new URLSearchParams({
      redirect_uri: process.env.REACT_APP_redirectUri,
      client_id: process.env.REACT_APP_clientId,
      prompt: "none",
      login_hint: localStorage.getItem("email"),
      response_type: "code",
      scope: "openid profile email",
      state: "renewal",
      code_challenge_method: "S256",
      code_challenge: code_challenge,
    });

    iframe.src = `${
      process.env.REACT_APP_authorizationEndpoint
    }?${searchParams.toString()}`;

    iframe.hidden = true;
    const timeoutID = setTimeout(() => {
      localStorage.setItem("IsUserLoggedIn", "false");
      removeIFrame();
      Promise.resolve(false);
    }, 60000);
    // console.log("addEventListener called");
    window.addEventListener("message", (event) => {
      if (event?.data === "success") {
        // console.log("iframe succes");
        clearTimeout(timeoutID);
        removeIFrame();

        Promise.resolve(JSON.parse(localStorage.getItem("token")));
      } else if (event?.data === "failure") {
        // console.log("failure");
        clearTimeout(timeoutID);
        removeIFrame();
        localStorage.setItem("IsUserLoggedIn", "false");

        Promise.resolve(false);
      }
    });
    // console.log(" document.body.appendChild");
    document.body.appendChild(iframe);
  });
};

const removeIFrame = async () => {
  const nviframe = document.getElementById("nviframe");
  if (nviframe) {
    //console.log("iframe removeIFrame");

    const storedToken = JSON.parse(localStorage.getItem("token"));
    await getUserInfo(storedToken);
    localStorage.removeItem("renewal");
    document.body.removeChild(nviframe);
  }
};

const getUserInfo = async (token, reload = true) => {
  if (token) {
    var idToken = token.id_token;
    if (idToken !== null && idToken !== undefined) {
      var tokenobj = jwt_decode(idToken);
      // console.log(tokenobj);
      localStorage.setItem("userinfo", JSON.stringify(tokenobj));
      localStorage.setItem("user", tokenobj.email.replace("@nvidia.com", ""));
      localStorage.setItem("email", tokenobj.email);
      localStorage.setItem("name", tokenobj.name);
      localStorage.setItem("sub", tokenobj.sub);
      localStorage.setItem("idp_name", tokenobj.idp_name);
      localStorage.setItem("email_verified", tokenobj.email_verified);
      localStorage.setItem("idp_id", tokenobj.idp_id);
      localStorage.setItem("issuer", tokenobj.iss);
      localStorage.setItem("external_id", tokenobj.external_id);
      localStorage.setItem("preferred_username", tokenobj.preferred_username);
      localStorage.setItem("updated_at", tokenobj.updated_at);
      localStorage.setItem("expire_at", tokenobj.exp);
      localStorage.setItem("issued_at", tokenobj.iat);
      localStorage.setItem("name", tokenobj.name);
      localStorage.removeItem("renewal");
      await setUserLoggedIn();

      // window.parent.postMessage("success");
    }
  }
};

const setUserLoggedIn = async () => {
  localStorage.setItem("IsUserLoggedIn", "true");
};

const generateCodeChallenge = async () => {
  // Generate a 32-octet random sequence
  const rand = window.crypto.getRandomValues(new Uint8Array(32));

  // The PKCE code verifier is the base64url encoding of the random octets
  const codeVerifier = b64url(rand);
  localStorage.setItem("CV", codeVerifier);

  // Calculate the SHA-256 hash of the code verifier
  const data = new TextEncoder().encode(codeVerifier);
  var sha256 = await window.crypto.subtle.digest("SHA-256", data);

  // The PKCE code challenge is the base64url encoding of the hash
  return b64url(new Uint8Array(sha256));
};
const b64url = (array) => {
  const b64 = btoa(String.fromCharCode.apply(null, array));
  return b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};
